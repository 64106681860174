<template>
  <div class="new-program-details">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Challenge Check Ins</h2>
          <v-btn
            v-if="!isCompleted"
            class="primary--text mr-5"
            text
            depressed
            :loading="loading"
            @click="saveForLater"
          >
            Save & Finish Later
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndNext"
            :loading="loading"
            text
            depressed
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6" class="mb-12">
        <challenge-checkin-form :for="challenge" :type="2" />

        <v-card v-if="hasCheckins" class="mt-7" outlined>
          <template v-for="(checkin, index) in checkins">
            <v-card-text :key="index">
              <div class="d-flex align-center justify-space-between">
                <div>
                  <div class="caption text--primary">
                    Checkin #{{ ++index }}
                  </div>
                  <div class="subtitle-1 primary--text">
                    {{ checkin | formatDate('MMMM DD, YYYY HH:mm') }}
                  </div>
                </div>

                <div>
                  <v-btn
                    color="error"
                    class="ma-2"
                    @click="removeCheckin(checkin)"
                    text
                  >
                    Remove
                  </v-btn>
                </div>
              </div>
            </v-card-text>

            <v-divider
              v-if="index < checkins.length"
              :key="`divider-${index}`"
            />
          </template>
        </v-card>

        <no-list v-else details="No Checkin List" />
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ChallengeCheckinForm from '@/components/forms/programs/ProgramCheckinForm'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import NoList from '@/components/elements/NoList'
import Challenge from '@/models/Challenge'

import { mdiArrowRight } from '@mdi/js'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'ChallengeCheckin',

  components: {
    NoList,
    ConfirmDialog,
    ChallengeCheckinForm,
  },

  data() {
    return {
      loading: false,
      challenge: new Challenge({ id: this.$attrs.id }),
      icons: {
        next: mdiArrowRight,
      },
    }
  },

  computed: {
    ...mapGetters({
      checkins: 'challenges/getSelectedChallengeCheckins',
    }),

    ...mapGetters({
      isCompleted: 'challenges/isSelectedChallengeComplete',
    }),

    hasCheckins() {
      return !!this.checkins.length
    },
  },

  created() {
    this.fetchChallengeCheckins()
  },

  methods: {
    ...mapMutations({
      deleteCheckin: 'challenges/deleteSelectedChallengeCheckin',
      setChallengeCheckins: 'challenges/setSelectedChallengeCheckins',
    }),

    async fetchChallengeCheckins() {
      let { data } = await this.challenge.checkins().get()
      this.setChallengeCheckins(data)
    },

    async removeCheckin(checkin) {
      const confirm = await this.$refs.confirmDelete.open(
        'Remove Checkin',
        'Are you sure you want to remove this checkin date?'
      )

      if (!confirm) return

      try {
        await checkin.for(this.challenge).delete()

        this.deleteCheckin(checkin)
      } catch (err) {
        return err
      }
    },

    saveForLater() {
      this.loading = true

      setTimeout(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'current.challenges',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'draft.challenges',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },

    saveAndNext() {
      this.loading = true

      setTimeout(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'challenge.variants',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'new.challenge.variants',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },
  },
}
</script>
